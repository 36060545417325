import React from "react";

var ProductStateContext = React.createContext();
var ProductDispatchContext = React.createContext();

var initialState =     {

  //basic info
  productName: "",
  categoryId: null,
  description: "",
  itemTypeId: 1,
  statusId: null,
  brandId: null,
  models : [{modelName : '', unitId: null, barcode: '', inventoryCode : 'calculate later', productCode : 'calculate later'}],
  specification: [],
  favorite: [],
  isForSale : false,
  imageId: null,

  //formula state
  productId : null,
  modelId : null,
  formulaNumber : null,
  productionOverHead : null,
  producedBatch : null,
  producedUnitId : null,
  standardProducedBatch : null,
  standardProducedUnitId : null,
  productionTime : null,
  formulaDescription : '',
  otherCost : null,
  foodItemId : null,
  foodUnitId : null,
  itemValue : null,
  baseMaterialId : null,
  positionId : null,
  numberOfPersonnel : null,
  timeOfWork : null,





  //pricing state
  discount: {value: false, label: "Hasn't"},
  salesVat: 0,
  unitId: null,
  price : 0,
  benefitPercent : 0,

  //extra info
  extraDescription : "",
  width : "",
  weight : "",
  height : "",
  volume : "",
  selectedStandards : [],
  selectedLicenses : []



}


function productReducer(state, action) {
  switch (action.type) {

    // BASIC INFO
    case "SET_PRODUCT_NAME":
      return {...state, productName: action.payload};
    case "SET_CAT_ID":
      return {...state, categoryId: action.payload};
    case "SET_DESCRIPTION":
      return {...state, description: action.payload};
    case "SET_PRODUCT_TYPE_ID":
      return {...state, itemTypeId: action.payload};
    case "SET_STATUS_ID":
      return {...state, statusId: action.payload};
    case "SET_BRAND_ID":
      return {...state, brandId: action.payload};
    case "SET_MODELS":
      return {...state, models: [...state.models, action.payload]};
    case "CHANGE_MODEL_NAME":
      const index1 = action.payload[0];
      const name = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index1), {
          ...state.models[index1],
          modelName: name
        }, ...state.models.slice(index1 + 1)
        ]
      };
    case "CHANGE_BARCODE":
      const index2 = action.payload[0];
      const bar = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index2), {
          ...state.models[index2],
          barcode: bar
        }, ...state.models.slice(index2 + 1)
        ]
      };
    case "CHANGE_UNIT_ID":
      const index3 = action.payload[0];
      const id = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index3), {
          ...state.models[index3],
          unitId: id
        }, ...state.models.slice(index3 + 1)
        ]
      };
    case "CHANGE_INVENTORY":
      const index5 = action.payload[0];
      const code = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index5), {
          ...state.models[index5],
          inventoryCode: code
        }, ...state.models.slice(index5 + 1)
        ]
      };
    case "CHANGE_PRODUCT_CODE":
      const index6 = action.payload[0];
      const code2 = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index6), {
          ...state.models[index6],
          productCode: code2
        }, ...state.models.slice(index6 + 1)
        ]
      };
    case "SET_SPECIFICATION":
      return {...state, specification: action.payload};
    case "SET_FAVORITE":
      return {...state, favorite: action.payload};
    case "SET_IMAGES":
      return {...state, images: action.payload};

      // FORMULA CASES
    case "SET_FORMULA_NUM":
      return {...state, formulaNumber: action.payload};
    case "SET_OVERHEAD":
      return {...state, productionOverHead: action.payload};
    case "SET_BATCH":
      return {...state, producedBatch: action.payload};
    case "SET_PRO_UNIT_ID":
      return {...state, producedUnitId: action.payload};
    case "SET_STANDARD_BATCH":
      return {...state, standardProducedBatch: action.payload};
    case "SET_STANDARD_UNIT_ID":
      return {...state, standardProducedUnitId: action.payload};
    case "SET_PRO_TIME":
      return {...state, productionTime: action.payload};
    case "SET_FORMULA_DESC":
      return {...state, formulaDescription: action.payload};
    case "SET_OTHER_COST":
      return {...state, otherCost: action.payload};
    case "SET_FOOD_ITEM_ID":
      return {...state, foodItemId: action.payload};
    case "SET_FOOD_UNIT_ID":
      return {...state, foodUnitId: action.payload};
    case "SET_ITEM_VALUE":
      return {...state, itemValue: action.payload};
    case "SET_MATERIAL_ID":
      return {...state, baseMaterialId: action.payload};
    case "SET_POSITION_ID":
      return {...state, positionId: action.payload};
    case "SET_NUM_OF_PERSONNEL":
      return {...state, numberOfPersonnel: action.payload};
    case "SET_TIME_OF_WORK":
      return {...state, timeOfWork: action.payload};

      // PRICING CASES
    case "SET_DISCOUNT":
      return {...state, discount: action.payload};
    case "SET_SALES_VAT":
      return {...state, salesVat: action.payload};
    case "SET_UNIT_ID":
      return {...state, unitId: action.payload};
    case "SET_PRICE":
      return {...state, price: action.payload};
    case "SET_BEN_PER":
      return {...state, benefitPercent: action.payload};
    case "SET_FOR_SALE":
      return {...state, isForSale: action.payload};

      // EXTRA CASES
    case "SET_PRODUCT_ID":
      return {...state, productId: action.payload};
    case "SET_EXTRA_DESC":
      return {...state, extraDescription: action.payload};
    case "SET_WIDTH":
      return {...state, width: action.payload};
    case "SET_WEIGHT":
      return {...state, weight: action.payload};
    case "SET_HEIGHT":
      return {...state, height: action.payload};
    case "SET_VOLUME":
      return {...state, volume: action.payload};
    case "SET_STANDARDS":
      return {...state, selectedStandards: action.payload};
    case "SET_LICENSES":
      return {...state, selectedLicenses: action.payload};

    case "RESET": {
      return initialState;
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ProductProvider({children}) {
  var [state, dispatch] = React.useReducer(productReducer, initialState );
  return (
    <ProductStateContext.Provider value={state}>
      <ProductDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDispatchContext.Provider>
    </ProductStateContext.Provider>
  );
}

function useProductState() {
  var context = React.useContext(ProductStateContext);
  if (context === undefined) {
    throw new Error("useProductState must be used within a ProductProvider");
  }
  return context;
}

function useProductDispatch() {
  var context = React.useContext(ProductDispatchContext);
  if (context === undefined) {
    throw new Error("useProductDispatch must be used within a ProductProvider");
  }
  return context;
}

export {
  ProductProvider,
  useProductState,
  useProductDispatch,

  // basic info setters
  setProductName,
  setCategoryId,
  setDescription,
  setItemTypeId,
  setStatusId,
  setBrandId,
  setModels,
  changeModelName,
  changeBarcode,
  changeUnitId,
  changeInventoryCode,
  changeProductCode,
  setSpecification,
  setFavorite,
  setIsForSale,
  setImageId,


  // pricing setters
  setDiscount,
  setSalesVat,
  setUnitId,
  setPrice,
  setBenefitPercent,

  // extra info setters
  setProductId,
  setExtraDescription,
  setWidth,
  setWeight,
  setVolume,
  setHeight,
  setSelectedStandards,
  setSelectedLicenses,

  resetProduct

};

// ###########################################################

function setProductName(dispatch, name) {
  dispatch({
    type: "SET_PRODUCT_NAME",
    payload: name
  });
}


function setCategoryId(dispatch, id) {
  dispatch({
    type: "SET_CAT_ID",
    payload: id
  });
}


function setDescription(dispatch, desc) {
  dispatch({
    type: "SET_DESCRIPTION",
    payload: desc
  });
}


function setItemTypeId(dispatch, id) {
  dispatch({
    type: "SET_PRODUCT_TYPE_ID",
    payload: id
  });
}


function setStatusId(dispatch, id) {
  dispatch({
    type: "SET_STATUS_ID",
    payload: id
  });
}


function setBrandId(dispatch, id) {
  dispatch({
    type: "SET_BRAND_ID",
    payload: id
  });
}


function setModels(dispatch, models) {
  dispatch({
    type: "SET_MODELS",
    payload: models
  });
}


function changeModelName(dispatch, index, val) {
  dispatch({
    type: "CHANGE_MODEL_NAME",
    payload: [index, val]
  });
}

function changeBarcode(dispatch, index, val) {
  dispatch({
    type: "CHANGE_BARCODE",
    payload: [index, val]
  });
}


function changeInventoryCode(dispatch, index, code) {
  dispatch({
    type: "CHANGE_INVENTORY",
    payload: [index, code]
  });
}


function changeProductCode(dispatch, index, code) {
  dispatch({
    type: "CHANGE_PRODUCT_CODE",
    payload: [index, code]
  });
}



function changeUnitId(dispatch, index, id) {
  dispatch({
    type: "CHANGE_UNIT_ID",
    payload: [index, id]
  });
}


function setSpecification(dispatch, spec) {
  dispatch({
    type: "SET_SPECIFICATION",
    payload: spec
  });
}


function setFavorite(dispatch, fav) {
  dispatch({
    type: "SET_FAVORITE",
    payload: fav
  });
}


function setImageId(dispatch, id) {
  dispatch({
    type: "SET_IMAGE_ID",
    payload: id
  });
}


// pricing

function setDiscount(dispatch, hasDis) {
  dispatch({
    type: "SET_DISCOUNT",
    payload: hasDis
  });
}


function setSalesVat(dispatch, vat) {
  dispatch({
    type: "SET_SALES_VAT",
    payload: vat
  });
}


function setUnitId(dispatch, id) {
  dispatch({
    type: "SET_UNIT_ID",
    payload: id
  });
}


function setPrice(dispatch, price) {
  dispatch({
    type: "SET_PRICE",
    payload: price
  });
}


function setBenefitPercent(dispatch, benPer) {
  dispatch({
    type: "SET_BEN_PER",
    payload: benPer
  });
}



function setIsForSale(dispatch, is) {
  dispatch({
    type: "SET_FOR_SALE",
    payload: is
  });
}

// extra info


function setProductId(dispatch, id) {
  dispatch({
    type: "SET_PRODUCT_ID",
    payload: id
  });
}


function setExtraDescription(dispatch, desc) {
  dispatch({
    type: "SET_EXTRA_DESC",
    payload: desc
  });
}


function setWidth(dispatch, width) {
  dispatch({
    type: "SET_WIDTH",
    payload: width
  });
}


function setWeight(dispatch, weight) {
  dispatch({
    type: "SET_WEIGHT",
    payload: weight
  });
}


function setHeight(dispatch, height) {
  dispatch({
    type: "SET_HEIGHT",
    payload: height
  });
}


function setVolume(dispatch, volume) {
  dispatch({
    type: "SET_VOLUME",
    payload: volume
  });
}


function setSelectedStandards(dispatch, ids) {
  dispatch({
    type: "SET_STANDARDS",
    payload: ids
  });
}


function setSelectedLicenses(dispatch, ids) {
  dispatch({
    type: "SET_LICENSES",
    payload: ids
  });
}


function resetProduct(dispatch) {
  dispatch({
    type: "RESET"
  });
}
