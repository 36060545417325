import React from "react";

var ItemStateContext = React.createContext();
var ItemDispatchContext = React.createContext();

var initialState = {

  //basic info
  itemName: "",
  categoryId: null,
  description: "",
  itemTypeId: null,
  statusId: null,
  brandId: null,
  models : [{modelName : '', unitId: null, barcode: '', inventoryCode : 'calculate later', itemCode : 'calculate later'}],
  specification: [],
  favorite: [],
  imageId: null,


  //extra info
  itemId : null,
  extraDescription : "",
  width : "",
  weight : "",
  height : "",
  volume : "",
  selectedStandards : [],
  selectedLicenses : [],


  // pricing
  discount: null,
  salesVat: 0,
  unitId: null,
  unitPrice : [],
  benefitPercent : 0,


}

function itemReducer(state, action) {
  switch (action.type) {

    // BASIC CASES
    case "SET_ITEM_NAME":
      return {...state, itemName: action.payload};
    case "SET_CAT_ID":
      return {...state, categoryId: action.payload};
    case "SET_DESCRIPTION":
      return {...state, description: action.payload};
    case "SET_ITEM_TYPE_ID":
      return {...state, itemTypeId: action.payload};
    case "SET_STATUS_ID":
      return {...state, statusId: action.payload};
    case "SET_BRAND_ID":
      return {...state, brandId: action.payload};
    case "SET_MODELS":
      return {...state, models: [...state.models, action.payload]};


    case "CHANGE_MODEL_NAME":
      const index1 = action.payload[0];
      const name = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index1), {
          ...state.models[index1],
          modelName: name
        }, ...state.models.slice(index1 + 1)
        ]
      };
    case "CHANGE_BARCODE":
      const index2 = action.payload[0];
      const bar = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index2), {
          ...state.models[index2],
          barcode: bar
        }, ...state.models.slice(index2 + 1)
        ]
      };
    case "CHANGE_UNIT_ID":
      const index3 = action.payload[0];
      const id = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index3), {
          ...state.models[index3],
          unitId: id
        }, ...state.models.slice(index3 + 1)
        ]
      };
    case "CHANGE_INVENTORY":
      const index5 = action.payload[0];
      const code = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index5), {
          ...state.models[index5],
          inventoryCode: code
        }, ...state.models.slice(index5 + 1)
        ]
      };
    case "CHANGE_ITEM_CODE":
      const index6 = action.payload[0];
      const code2 = action.payload[1];
      return {
        ...state, models: [...state.models.slice(0, index6), {
          ...state.models[index6],
          itemCode: code2
        }, ...state.models.slice(index6 + 1)
        ]
      };
    case "SET_SPECIFICATION":
      return {...state, specification: action.payload};
    case "SET_FAVORITE":
      return {...state, favorite: action.payload};
    case "SET_IMAGE_ID":
      return {...state, imageId: action.payload};


      // EXTRA CASES
    case "SET_ITEM_ID":
      return {...state, itemId: action.payload};
    case "SET_EXTRA_DESC":
      return {...state, extraDescription: action.payload};
    case "SET_WIDTH":
      return {...state, width: action.payload};
    case "SET_WEIGHT":
      return {...state, weight: action.payload};
    case "SET_HEIGHT":
      return {...state, height: action.payload};
    case "SET_VOLUME":
      return {...state, volume: action.payload};
    case "SET_STANDARDS":
      return {...state, selectedStandards: action.payload};
    case "SET_LICENSES":
      return {...state, selectedLicenses: action.payload};

      // PRICING CASES
    case "SET_DISCOUNT":
      return {...state, discount: action.payload};
    case "SET_SALES_VAT":
      return {...state, salesVat: action.payload};
    case "SET_UNIT_PRICE":
      return {...state, unitPrice: action.payload};
    case "CHANGE_PRICE":
      const index4 = action.payload[0];
      const pr = action.payload[1];
      return {
        ...state, unitPrice: [...state.unitPrice.slice(0, index4), {
          ...state.unitPrice[index4],
          price: pr
        }, ...state.unitPrice.slice(index4 + 1)
        ]
      };
    case "SET_BEN_PER":
      return {...state, benefitPercent: action.payload};

    case "RESET": {
      return initialState;
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ItemProvider({children}) {
  var [state, dispatch] = React.useReducer(itemReducer, initialState);
  return (
    <ItemStateContext.Provider value={state}>
      <ItemDispatchContext.Provider value={dispatch}>
        {children}
      </ItemDispatchContext.Provider>
    </ItemStateContext.Provider>
  );
}

function useItemState() {
  var context = React.useContext(ItemStateContext);
  if (context === undefined) {
    throw new Error("useItemState must be used within a ItemProvider");
  }
  return context;
}

function useItemDispatch() {
  var context = React.useContext(ItemDispatchContext);
  if (context === undefined) {
    throw new Error("useItemDispatch must be used within a ItemProvider");
  }
  return context;
}

export {
  ItemProvider,
  useItemState,
  useItemDispatch,

  // basic info setters
  setItemName,
  setCategoryId,
  setDescription,
  setItemTypeId,
  setStatusId,
  setBrandId,
  setModels,
  changeModelName,
  changeBarcode,
  changeUnitId,
  changeInventoryCode,
  changeItemCode,
  setSpecification,
  setFavorite,
  setImageId,

  setModelName,
  setUnitId,
  setBarcode,

  // extra info setters
  setItemId,
  setExtraDescription,
  setWidth,
  setWeight,
  setVolume,
  setHeight,
  setSelectedStandards,
  setSelectedLicenses,


  // pricing setters
  setDiscount,
  setSalesVat,
  setUnitPrice,
  changePrice,
  setBenefitPercent,

  resetItem

};

// ###########################################################

function setItemName(dispatch, name) {
  dispatch({
    type: "SET_ITEM_NAME",
    payload: name
  });
}


function setCategoryId(dispatch, id) {
  dispatch({
    type: "SET_CAT_ID",
    payload: id
  });
}


function setDescription(dispatch, desc) {
  dispatch({
    type: "SET_DESCRIPTION",
    payload: desc
  });
}


function setItemTypeId(dispatch, id) {
  dispatch({
    type: "SET_ITEM_TYPE_ID",
    payload: id
  });
}


function setStatusId(dispatch, id) {
  dispatch({
    type: "SET_STATUS_ID",
    payload: id
  });
}


function setBrandId(dispatch, id) {
  dispatch({
    type: "SET_BRAND_ID",
    payload: id
  });
}


function setModels(dispatch, models) {
  dispatch({
      type: "SET_MODELS",
    payload: models
  });
}


function changeModelName(dispatch, index, val) {
  dispatch({
    type: "CHANGE_MODEL_NAME",
    payload: [index, val]
  });
}

function changeBarcode(dispatch, index, val) {
  dispatch({
    type: "CHANGE_BARCODE",
    payload: [index, val]
  });
}


function changeUnitId(dispatch, index, id) {
  dispatch({
    type: "CHANGE_UNIT_ID",
    payload: [index, id]
  });
}


function changeInventoryCode(dispatch, index, code) {
  dispatch({
    type: "CHANGE_INVENTORY",
    payload: [index, code]
  });
}


function changeItemCode(dispatch, index, code) {
  dispatch({
    type: "CHANGE_ITEM_CODE",
    payload: [index, code]
  });
}


function setSpecification(dispatch, spec) {
  dispatch({
    type: "SET_SPECIFICATION",
    payload: spec
  });
}


function setFavorite(dispatch, fav) {
  dispatch({
    type: "SET_FAVORITE",
    payload: fav
  });
}


function setImageId(dispatch, id) {
  dispatch({
    type: "SET_IMAGE_ID",
    payload: id
  });
}


// extra info


function setItemId(dispatch, id) {
  dispatch({
    type: "SET_ITEM_ID",
    payload: id
  });
}


function setExtraDescription(dispatch, desc) {
  dispatch({
    type: "SET_EXTRA_DESC",
    payload: desc
  });
}


function setWidth(dispatch, width) {
  dispatch({
    type: "SET_WIDTH",
    payload: width
  });
}


function setWeight(dispatch, weight) {
  dispatch({
    type: "SET_WEIGHT",
    payload: weight
  });
}


function setHeight(dispatch, height) {
  dispatch({
    type: "SET_HEIGHT",
    payload: height
  });
}


function setVolume(dispatch, volume) {
  dispatch({
    type: "SET_VOLUME",
    payload: volume
  });
}


function setSelectedStandards(dispatch, ids) {
  dispatch({
    type: "SET_STANDARDS",
    payload: ids
  });
}


function setSelectedLicenses(dispatch, ids) {
  dispatch({
    type: "SET_LICENSES",
    payload: ids
  });
}


//  pricing

function setDiscount(dispatch, hasDis) {
  dispatch({
    type: "SET_DISCOUNT",
    payload: hasDis
  });
}


function setSalesVat(dispatch, vat) {
  dispatch({
    type: "SET_SALES_VAT",
    payload: vat
  });
}



function setUnitPrice(dispatch, price) {
  dispatch({
    type: "SET_UNIT_PRICE",
    payload: price
  });
}


function changePrice(dispatch, index, price) {
  dispatch({
    type: "CHANGE_PRICE",
    payload: [index, price]
  });
}


function setBenefitPercent(dispatch, benPer) {
  dispatch({
    type: "SET_BEN_PER",
    payload: benPer
  });
}


function resetItem(dispatch) {
  dispatch({
    type: "RESET"
  });
}

function setModelName(dispatch, name) {
  dispatch({
    type: "SET_MODEL_NAME",
    payload: name
  });
}

function setUnitId(dispatch, id) {
  dispatch({
    type: "SET_UNIT_ID",
    payload: id
  });
}

function setBarcode(dispatch, barcode) {
  dispatch({
    type: "SET_BARCODE",
    payload: barcode
  });
}
