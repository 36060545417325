import React from "react";

var FormulaStateContext = React.createContext();
var FormulaDispatchContext = React.createContext();

var initialState = {
  formulas: []

};

function formulaReducer(state, action) {
  switch (action.type) {
    case "SET_FORMULAS":
      return { ...state, formulas: action.payload };

    case "CHANGE_FORMULA_NUMBER":
      const index1 = action.payload[0];
      const num = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index1), {
          ...state.formulas[index1],
          formulaNumber: num
        }, ...state.formulas.slice(index1 + 1)
        ]
      };

    case "CHANGE_OVER_HEAD":
      const index2 = action.payload[0];
      const over = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index2), {
          ...state.formulas[index2],
          productionOverHead: over
        }, ...state.formulas.slice(index2 + 1)
        ]
      };

    case "CHANGE_PRODUCED_BATCH":
      const index3 = action.payload[0];
      const batch = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index3), {
          ...state.formulas[index3],
          producedBatch: batch
        }, ...state.formulas.slice(index3 + 1)
        ]
      };

    case "CHANGE_PRO_UNIT_ID":
      const index4 = action.payload[0];
      const id = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index4), {
          ...state.formulas[index4],
          producedUnitId: id
        }, ...state.formulas.slice(index4 + 1)
        ]
      };

    case "CHANGE_STANDARD_BATCH":
      const index5 = action.payload[0];
      const sBatch = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index5), {
          ...state.formulas[index5],
          standardProducedBatch: sBatch
        }, ...state.formulas.slice(index5 + 1)
        ]
      };

    case "CHANGE_STANDARD_UNIT_ID":
      const index6 = action.payload[0];
      const sId = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index6), {
          ...state.formulas[index6],
          standardProducedUnitId: sId
        }, ...state.formulas.slice(index6 + 1)
        ]
      };

    case "CHANGE_PRO_TIME":
      const index7 = action.payload[0];
      const time = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index7), {
          ...state.formulas[index7],
          productionTime: time
        }, ...state.formulas.slice(index7 + 1)
        ]
      };

    case "CHANGE_FORMULA_DESC":
      const index8 = action.payload[0];
      const desc = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index8), {
          ...state.formulas[index8],
          description: desc
        }, ...state.formulas.slice(index8 + 1)
        ]
      };

    case "CHANGE_OTHER_COST":
      const index9 = action.payload[0];
      const cost = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index9), {
          ...state.formulas[index9],
          otherCost: cost
        }, ...state.formulas.slice(index9 + 1)
        ]
      };

    case "SET_ITEMS":
      const index10 = action.payload[0];
      const item = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index10), {
          ...state.formulas[index10],
          foodFormulaStep : [...state.formulas[index10].foodFormulaStep, item]
        }, ...state.formulas.slice(index10 + 1)
        ]
      };

    case "SET_HUMANS":
      const index11 = action.payload[0];
      const human = action.payload[1];
      return {
        ...state, formulas: [...state.formulas.slice(0, index11), {
          ...state.formulas[index11],
          personalFormulaStep : [...state.formulas[index11].personalFormulaStep, human]
        }, ...state.formulas.slice(index11 + 1)
        ]
      };

      // Food table

    case "CHANGE_ITEM_ID":
      const tabIdx1 = action.payload[0];
      const itemIdx1 = action.payload[1];
      const id1 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx1), {
          ...state.formulas[tabIdx1],
            foodFormulaStep : [ ...state.formulas[tabIdx1].foodFormulaStep.slice(0,itemIdx1), {
              ...state.formulas[tabIdx1].foodFormulaStep[itemIdx1],
              itemId : id1
            }, ...state.formulas[tabIdx1].foodFormulaStep.slice(itemIdx1 + 1)
          ]
        }, ...state.formulas.slice(tabIdx1 + 1)
        ]
      };

    case "CHANGE_INVENTORY":
      const tabIdx2 = action.payload[0];
      const itemIdx2 = action.payload[1];
      const id2 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx2), {
          ...state.formulas[tabIdx2],
            foodFormulaStep : [ ...state.formulas[tabIdx2].foodFormulaStep.slice(0,itemIdx2), {
              ...state.formulas[tabIdx2].foodFormulaStep[itemIdx2],
              inventory : id2
            }, ...state.formulas[tabIdx2].foodFormulaStep.slice(itemIdx2 + 1)
          ]
        }, ...state.formulas.slice(tabIdx2 + 1)
        ]
      };

    case "CHANGE_ITEM_NAME":
      const tabIdx3 = action.payload[0];
      const itemIdx3 = action.payload[1];
      const id3 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx3), {
          ...state.formulas[tabIdx3],
            foodFormulaStep : [ ...state.formulas[tabIdx3].foodFormulaStep.slice(0,itemIdx3), {
              ...state.formulas[tabIdx3].foodFormulaStep[itemIdx3],
              itemName : id3
            }, ...state.formulas[tabIdx3].foodFormulaStep.slice(itemIdx3 + 1)
          ]
        }, ...state.formulas.slice(tabIdx3 + 1)
        ]
      };

    case "CHANGE_FOOD_UNIT_ID":
      const tabIdx4 = action.payload[0];
      const itemIdx4 = action.payload[1];
      const id4 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx4), {
          ...state.formulas[tabIdx4],
            foodFormulaStep : [ ...state.formulas[tabIdx4].foodFormulaStep.slice(0,itemIdx4), {
              ...state.formulas[tabIdx4].foodFormulaStep[itemIdx4],
              unitId : id4
            }, ...state.formulas[tabIdx4].foodFormulaStep.slice(itemIdx4 + 1)
          ]
        }, ...state.formulas.slice(tabIdx4 + 1)
        ]
      };

    case "CHANGE_ITEM_VALUE":
      const tabIdx5 = action.payload[0];
      const itemIdx5 = action.payload[1];
      const id5 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx5), {
          ...state.formulas[tabIdx5],
            foodFormulaStep : [ ...state.formulas[tabIdx5].foodFormulaStep.slice(0,itemIdx5), {
              ...state.formulas[tabIdx5].foodFormulaStep[itemIdx5],
              itemValue : id5
            }, ...state.formulas[tabIdx5].foodFormulaStep.slice(itemIdx5 + 1)
          ]
        }, ...state.formulas.slice(tabIdx5 + 1)
        ]
      };

    case "CHANGE_AMOUNT":
      const tabIdx6 = action.payload[0];
      const itemIdx6 = action.payload[1];
      const id6 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx6), {
          ...state.formulas[tabIdx6],
            foodFormulaStep : [ ...state.formulas[tabIdx6].foodFormulaStep.slice(0,itemIdx6), {
              ...state.formulas[tabIdx6].foodFormulaStep[itemIdx6],
              amount : id6
            }, ...state.formulas[tabIdx6].foodFormulaStep.slice(itemIdx6 + 1)
          ]
        }, ...state.formulas.slice(tabIdx6 + 1)
        ]
      };

    case "CHANGE_MATERIAL_ID":
      const tabIdx7 = action.payload[0];
      const itemIdx7 = action.payload[1];
      const id7 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx7), {
          ...state.formulas[tabIdx7],
            foodFormulaStep : [ ...state.formulas[tabIdx7].foodFormulaStep.slice(0,itemIdx7), {
              ...state.formulas[tabIdx7].foodFormulaStep[itemIdx7],
              baseMaterialId : id7
            }, ...state.formulas[tabIdx7].foodFormulaStep.slice(itemIdx7 + 1)
          ]
        }, ...state.formulas.slice(tabIdx7 + 1)
        ]
      };

      // human table

    case "CHANGE_POSITION_ID":
      const tabIdx8 = action.payload[0];
      const itemIdx8 = action.payload[1];
      const id8 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx8), {
          ...state.formulas[tabIdx8],
          personalFormulaStep : [ ...state.formulas[tabIdx8].personalFormulaStep.slice(0,itemIdx8), {
            ...state.formulas[tabIdx8].personalFormulaStep[itemIdx8],
            positionId : id8
          }, ...state.formulas[tabIdx8].personalFormulaStep.slice(itemIdx8 + 1)
          ]
        }, ...state.formulas.slice(tabIdx8 + 1)
        ]
      };

    case "CHANGE_POSITION_TXT":
      const tabIdx9 = action.payload[0];
      const itemIdx9 = action.payload[1];
      const id9 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx9), {
          ...state.formulas[tabIdx9],
          personalFormulaStep : [ ...state.formulas[tabIdx9].personalFormulaStep.slice(0,itemIdx9), {
            ...state.formulas[tabIdx9].personalFormulaStep[itemIdx9],
            positionTxt : id9
          }, ...state.formulas[tabIdx9].personalFormulaStep.slice(itemIdx9 + 1)
          ]
        }, ...state.formulas.slice(tabIdx9 + 1)
        ]
      };

    case "CHANGE_NUM_OF_PERSONNEL":
      const tabIdx10 = action.payload[0];
      const itemIdx10 = action.payload[1];
      const id10 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx10), {
          ...state.formulas[tabIdx10],
          personalFormulaStep : [ ...state.formulas[tabIdx10].personalFormulaStep.slice(0,itemIdx10), {
            ...state.formulas[tabIdx10].personalFormulaStep[itemIdx10],
            numberOfPersonnel : id10
          }, ...state.formulas[tabIdx10].personalFormulaStep.slice(itemIdx10 + 1)
          ]
        }, ...state.formulas.slice(tabIdx10 + 1)
        ]
      };

    case "CHANGE_TIME_OF_WORK":
      const tabIdx11 = action.payload[0];
      const itemIdx11 = action.payload[1];
      const id11 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx11), {
          ...state.formulas[tabIdx11],
          personalFormulaStep : [ ...state.formulas[tabIdx11].personalFormulaStep.slice(0,itemIdx11), {
            ...state.formulas[tabIdx11].personalFormulaStep[itemIdx11],
            timeOfWork : id11
          }, ...state.formulas[tabIdx11].personalFormulaStep.slice(itemIdx11 + 1)
          ]
        }, ...state.formulas.slice(tabIdx11 + 1)
        ]
      };

    case "CHANGE_HOURLY_WAGES":
      const tabIdx12 = action.payload[0];
      const itemIdx12 = action.payload[1];
      const id12 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx12), {
          ...state.formulas[tabIdx12],
          personalFormulaStep : [ ...state.formulas[tabIdx12].personalFormulaStep.slice(0,itemIdx12), {
            ...state.formulas[tabIdx12].personalFormulaStep[itemIdx12],
            hourlyWages : id12
          }, ...state.formulas[tabIdx12].personalFormulaStep.slice(itemIdx12 + 1)
          ]
        }, ...state.formulas.slice(tabIdx12 + 1)
        ]
      };

    case "CHANGE_HUMAN_AMOUNT":
      const tabIdx13 = action.payload[0];
      const itemIdx13 = action.payload[1];
      const id13 = action.payload[2];
      return {
        ...state, formulas: [...state.formulas.slice(0, tabIdx13), {
          ...state.formulas[tabIdx13],
          personalFormulaStep : [ ...state.formulas[tabIdx13].personalFormulaStep.slice(0,itemIdx13), {
            ...state.formulas[tabIdx13].personalFormulaStep[itemIdx13],
            amount : id13
          }, ...state.formulas[tabIdx13].personalFormulaStep.slice(itemIdx13 + 1)
          ]
        }, ...state.formulas.slice(tabIdx13 + 1)
        ]
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function FormulaProvider({children}) {
  var [state, dispatch] = React.useReducer(formulaReducer, initialState);
  return (
    <FormulaStateContext.Provider value={state}>
      <FormulaDispatchContext.Provider value={dispatch}>
        {children}
      </FormulaDispatchContext.Provider>
    </FormulaStateContext.Provider>
  );
}

function useFormulaState() {
  var context = React.useContext(FormulaStateContext);
  if (context === undefined) {
    throw new Error("useFormulaState must be used within a FormulaProvider");
  }
  return context;
}

function useFormulaDispatch() {
  var context = React.useContext(FormulaDispatchContext);
  if (context === undefined) {
    throw new Error("useFormulaDispatch must be used within a FormulaProvider");
  }
  return context;
}

export {
  FormulaProvider,
  useFormulaState,
  useFormulaDispatch,

  setFormulas,

  changeFormulaNumber ,
  changeProductionOverHead ,
  changeProducedBatch ,
  changeProducedUnitId ,
  changeStandardProducedBatch ,
  changeStandardProducedUnitId ,
  changeProductionTime ,
  changeFormulaDescription ,
  changeOtherCost ,
  setItems,
  setHumans,

  changeItemId ,
  changeInventory,
  changeItemName,
  changeAmount,
  changeFoodUnitId ,
  changeItemValue ,
  changeBaseMaterialId ,


  changePositionId ,
  changePositionTxt ,
  changeNumOfPersonnel ,
  changeTimeOfWork ,
  changeHourlyWages ,
  changeHumanAmount ,

};

// ###########################################################


function setFormulas(dispatch, formulas) {
  dispatch({
    type: "SET_FORMULAS",
    payload : formulas
  });
}

function changeFormulaNumber(dispatch, index, num) {
  dispatch({
    type: "CHANGE_FORMULA_NUMBER",
    payload: [index, num]
  });
}

function changeProductionOverHead(dispatch, index, over) {
  dispatch({
    type: "CHANGE_OVER_HEAD",
    payload: [index, over]
  });
}

function changeProducedBatch(dispatch, index, batch) {
  dispatch({
    type: "CHANGE_PRODUCED_BATCH",
    payload: [index, batch]
  });
}

function changeProducedUnitId(dispatch, index, id) {
  dispatch({
    type: "CHANGE_PRO_UNIT_ID",
    payload: [index, id]
  });
}

function changeStandardProducedBatch(dispatch, index, batch) {
  dispatch({
    type: "CHANGE_STANDARD_BATCH",
    payload: [index, batch]
  });
}


function changeStandardProducedUnitId(dispatch, index, id) {
  dispatch({
    type: "CHANGE_STANDARD_UNIT_ID",
    payload: [index, id]
  });
}

function changeProductionTime(dispatch, index, time) {
  dispatch({
    type: "CHANGE_PRO_TIME",
    payload: [index, time]
  });
}

function changeFormulaDescription(dispatch, index, desc) {
  dispatch({
    type: "CHANGE_FORMULA_DESC",
    payload: [index, desc]
  });
}

function changeOtherCost(dispatch, index, cost) {
  dispatch({
    type: "CHANGE_OTHER_COST",
    payload: [index, cost]
  });
}

function setItems(dispatch, index, item) {
  dispatch({
    type: "SET_ITEMS",
    payload: [index, item]
  });
}

function setHumans(dispatch, index, human) {
  dispatch({
    type: "SET_HUMANS",
    payload: [index, human]
  });
}


// food

function changeItemId(dispatch, tabIdx, itemIdx, id) {
  dispatch({
    type: "CHANGE_ITEM_ID",
    payload: [tabIdx, itemIdx, id]
  });
}

function changeInventory(dispatch, tabIdx, itemIdx, code) {
  dispatch({
    type: "CHANGE_INVENTORY",
    payload: [tabIdx, itemIdx, code]
  });
}

function changeItemName(dispatch, tabIdx, itemIdx, name) {
  dispatch({
    type: "CHANGE_ITEM_NAME",
    payload: [tabIdx, itemIdx, name]
  });
}

function changeFoodUnitId(dispatch, tabIdx, itemIdx,  id) {
  dispatch({
    type: "CHANGE_FOOD_UNIT_ID",
    payload: [tabIdx, itemIdx, id]
  });
}

function changeItemValue(dispatch, tabIdx, itemIdx,  val) {
  dispatch({
    type: "CHANGE_ITEM_VALUE",
    payload: [tabIdx, itemIdx, val]
  });
}

function changeAmount(dispatch, tabIdx, itemIdx,  amount) {
  dispatch({
    type: "CHANGE_AMOUNT",
    payload: [tabIdx, itemIdx, amount]
  });
}

function changeBaseMaterialId(dispatch, tabIdx, itemIdx, mat) {
  dispatch({
    type: "CHANGE_MATERIAL_ID",
    payload: [tabIdx, itemIdx, mat]
  });
}

// human

function changePositionId(dispatch, tabIdx, itemIdx, id) {
  dispatch({
    type: "CHANGE_POSITION_ID",
    payload: [ tabIdx, itemIdx, id]
  });
}

function changePositionTxt(dispatch, tabIdx, itemIdx, txt) {
  dispatch({
    type: "CHANGE_POSITION_TXT",
    payload: [ tabIdx, itemIdx, txt]
  });
}

function changeNumOfPersonnel(dispatch, tabIdx, itemIdx,  num) {
  dispatch({
    type: "CHANGE_NUM_OF_PERSONNEL",
    payload: [ tabIdx, itemIdx, num]
  });
}

function changeTimeOfWork(dispatch, tabIdx, itemIdx,  time) {
  dispatch({
    type: "CHANGE_TIME_OF_WORK",
    payload: [ tabIdx, itemIdx, time]
  });
}

function changeHourlyWages(dispatch, tabIdx, itemIdx,  wages) {
  dispatch({
    type: "CHANGE_HOURLY_WAGES",
    payload: [ tabIdx, itemIdx, wages]
  });
}

function changeHumanAmount(dispatch, tabIdx, itemIdx,  amount) {
  dispatch({
    type: "CHANGE_HUMAN_AMOUNT",
    payload: [ tabIdx, itemIdx, amount]
  });
}
