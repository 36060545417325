import React from "react";

var DealsStateContext = React.createContext();
var DealsDispatchContext = React.createContext();


var initialState = {

  //basic info
  dealsName: "",
  categoryId: null,
  description: "",
  itemTypeId: 1,
  statusId: null,
  brandId: null,
  modelName : '',
  unitId: null,
  barcode: '',
  specification: [],
  favorite: [],
  imageId: null,

  // content
  dealsId: null,
  dealsItem: [{itemId: null, inventory: '', itemName: '', unitId: null, itemValue: 0, amount : null}],
  discount: null,
  salesVat: 0,
  price: 0,
  benefitPercent: 0,


}

function dealsReducer(state, action) {
  switch (action.type) {
    case "SET_DEALS_NAME":
      return {...state, dealsName: action.payload};
    case "SET_CAT_ID":
      return {...state, categoryId: action.payload};
    case "SET_DESCRIPTION":
      return {...state, description: action.payload};
    case "SET_ITEM_TYPE_ID":
      return {...state, itemTypeId: action.payload};
    case "SET_STATUS_ID":
      return {...state, statusId: action.payload};
    case "SET_BRAND_ID":
      return {...state, brandId: action.payload};
    case "SET_MODEL_NAME":
      return {...state, modelName: action.payload};
    case "SET_BARCODE":
      return {...state, barcode: action.payload};
    case "SET_UNIT_ID":
      return {...state, unitId: action.payload};
    case "SET_SPECIFICATION":
      return {...state, specification: action.payload};
    case "SET_FAVORITE":
      return {...state, favorite: action.payload};
    case "SET_IMAGE_ID":
      return {...state, imageId: action.payload};


    // CONTENT CASES
    case "SET_DEALS_ID":
      return {...state, dealsId: action.payload};
    case "SET_DEALS_ITEM":
      return {...state, dealsItem: [...state.dealsItem, action.payload]};
    case "CHANGE_ITEM_ID":
      const index1 = action.payload[0];
      const id1 = action.payload[1];
      return {
        ...state, dealsItem: [...state.dealsItem.slice(0, index1), {
          ...state.dealsItem[index1],
          itemId: id1
        }, ...state.dealsItem.slice(index1 + 1)
        ]
      };
    case "CHANGE_INVENTORY":
      const index2 = action.payload[0];
      const code = action.payload[1];
      return {
        ...state, dealsItem: [...state.dealsItem.slice(0, index2), {
          ...state.dealsItem[index2],
          inventory: code
        }, ...state.dealsItem.slice(index2 + 1)
        ]
      };
    case "CHANGE_ITEM_NAME":
      const index3 = action.payload[0];
      const name = action.payload[1];
      return {
        ...state, dealsItem: [...state.dealsItem.slice(0, index3), {
          ...state.dealsItem[index3],
          itemName: name
        }, ...state.dealsItem.slice(index3 + 1)
        ]
      };
    case "CHANGE_CONTENT_UNIT_ID":
      const index4 = action.payload[0];
      const id2 = action.payload[1];
      return {
        ...state, dealsItem: [...state.dealsItem.slice(0, index4), {
          ...state.dealsItem[index4],
          unitId: id2
        }, ...state.dealsItem.slice(index4 + 1)
        ]
      };
    case "CHANGE_ITEM_VALUE":
      const index5 = action.payload[0];
      const val = action.payload[1];
      return {
        ...state, dealsItem: [...state.dealsItem.slice(0, index5), {
          ...state.dealsItem[index5],
          itemValue: val
        }, ...state.dealsItem.slice(index5 + 1)
        ]
      };
    case "CHANGE_AMOUNT":
      const index6 = action.payload[0];
      const amount = action.payload[1];
      return {
        ...state, dealsItem: [...state.dealsItem.slice(0, index6), {
          ...state.dealsItem[index6],
          amount: amount
        }, ...state.dealsItem.slice(index6 + 1)
        ]
      };
    case "SET_DISCOUNT":
      return {...state, discount: action.payload};
    case "SET_SALES_VAT":
      return {...state, salesVat: action.payload};
    case "SET_PRICE":
      return {...state, price: action.payload};
    case "SET_BEN_PER":
      return {...state, benefitPercent: action.payload};

    case "RESET": {
      return initialState;
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DealsProvider({children}) {
  var [state, dispatch] = React.useReducer(dealsReducer, initialState);
  return (
    <DealsStateContext.Provider value={state}>
      <DealsDispatchContext.Provider value={dispatch}>
        {children}
      </DealsDispatchContext.Provider>
    </DealsStateContext.Provider>
  );
}

function useDealsState() {
  var context = React.useContext(DealsStateContext);
  if (context === undefined) {
    throw new Error("useDealsState must be used within a DealsProvider");
  }
  return context;
}

function useDealsDispatch() {
  var context = React.useContext(DealsDispatchContext);
  if (context === undefined) {
    throw new Error("useDealsDispatch must be used within a DealsProvider");
  }
  return context;
}

export {
  DealsProvider,
  useDealsState,
  useDealsDispatch,

  // basic info setters
  setDealsName,
  setCategoryId,
  setDescription,
  setItemTypeId,
  setStatusId,
  setBrandId,
  setModelName,
  setBarcode,
  setUnitId,
  setSpecification,
  setFavorite,
  setImageId,

  // content setters
  setDealsId,
  setDealsItem,
  changeItemId,
  changeInventory,
  changeItemName,
  changeContentUnitId,
  changeItemValue,
  changeAmount,
  setDiscount,
  setSalesVat,
  setPrice,
  setBenefitPercent,

  resetDeals
};

// ###########################################################

function setDealsName(dispatch, name) {
  dispatch({
    type: "SET_DEALS_NAME",
    payload: name
  });
}


function setCategoryId(dispatch, id) {
  dispatch({
    type: "SET_CAT_ID",
    payload: id
  });
}




function setDescription(dispatch, desc) {
  dispatch({
    type: "SET_DESCRIPTION",
    payload: desc
  });
}


function setItemTypeId(dispatch, id) {
  dispatch({
    type: "SET_ITEM_TYPE_ID",
    payload: id
  });
}


function setStatusId(dispatch, id) {
  dispatch({
    type: "SET_STATUS_ID",
    payload: id
  });
}


function setBrandId(dispatch, id) {
  dispatch({
    type: "SET_BRAND_ID",
    payload: id
  });
}


function setModelName(dispatch, name) {
  dispatch({
    type: "SET_MODEL_NAME",
    payload: name
  });
}


function setBarcode(dispatch, barcode) {
  dispatch({
    type: "SET_BARCODE",
    payload: barcode
  });
}


function setUnitId(dispatch, id) {
  dispatch({
    type: "SET_UNIT_ID",
    payload: id
  });
}


function setSpecification(dispatch, spec) {
  dispatch({
    type: "SET_SPECIFICATION",
    payload: spec
  });
}


function setFavorite(dispatch, fav) {
  dispatch({
    type: "SET_FAVORITE",
    payload: fav
  });
}


function setImageId(dispatch, image) {
  dispatch({
    type: "SET_IMAGE_ID",
    payload: image
  });
}

// deals content


function setDealsId(dispatch, id) {
  dispatch({
    type: "SET_DEALS_ID",
    payload: id
  });
}


function setDealsItem(dispatch, item) {
  dispatch({
    type: "SET_DEALS_ITEM",
    payload: item
  });
}


function changeItemId(dispatch, index, id) {
  dispatch({
    type: "CHANGE_ITEM_ID",
    payload: [index, id]
  });
}


function changeInventory(dispatch, index, code) {
  dispatch({
    type: "CHANGE_INVENTORY",
    payload: [index, code]
  });
}


function changeItemName(dispatch, index, name) {
  dispatch({
    type: "CHANGE_ITEM_NAME",
    payload: [index, name]
  });
}


function changeContentUnitId(dispatch, index, id) {
  dispatch({
    type: "CHANGE_CONTENT_UNIT_ID",
    payload: [index, id]
  });
}


function changeItemValue(dispatch, index, val) {
  dispatch({
    type: "CHANGE_ITEM_VALUE",
    payload: [index, val]
  });
}


function changeAmount(dispatch, index, amount) {
  dispatch({
    type: "CHANGE_AMOUNT",
    payload: [index, amount]
  });
}


function setDiscount(dispatch, hasDis) {
  dispatch({
    type: "SET_DISCOUNT",
    payload: hasDis
  });
}


function setSalesVat(dispatch, vat) {
  dispatch({
    type: "SET_SALES_VAT",
    payload: vat
  });
}


function setPrice(dispatch, price) {
  dispatch({
    type: "SET_PRICE",
    payload: price
  });
}


function setBenefitPercent(dispatch, benPer) {
  dispatch({
    type: "SET_BEN_PER",
    payload: benPer
  });
}


function resetDeals(dispatch) {
  dispatch({
    type: "RESET"
  });
}



